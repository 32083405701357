





















import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ListOfAssets } from '@/modules/listOfAssets/types';
import { ConservatorshipDetails } from '@/modules/conservatorship/types';
import { FETCH_LISTS_OF_ASSETS, LIST_OF_ASSETS_BY_CONSERVATORSHIP } from '@/modules/listOfAssets/store';
import { ApiResponse } from '@/components/types';
import BankAccountsPlugin from '@/modules/listOfAssets/components/BankAccountsPlugin.vue';
import CashAccountsPlugin from '@/modules/listOfAssets/components/CashAccountsPlugin.vue';
import CreditAccountsPlugin from '@/modules/listOfAssets/components/CreditAccountsPlugin.vue';
import InternalAccountsPlugin from '@/modules/listOfAssets/components/InternalAccountsPlugin.vue';
import FinanceAccountTitlePlugin from '@/modules/listOfAssets/components/details/cards/FinanceAccountTitlePlugin.vue';

const ListOfAssets = namespace('listOfAssets');

@Component({
  components: { FinanceAccountTitlePlugin, CreditAccountsPlugin, CashAccountsPlugin, BankAccountsPlugin, InternalAccountsPlugin }
})
export default class FinanceAccountTab extends Vue {
  @Prop({ type: Object }) conservatorship?: ConservatorshipDetails;

  @ListOfAssets.Action(FETCH_LISTS_OF_ASSETS) fetchList!: () => Promise<ApiResponse>;
  @ListOfAssets.Getter(LIST_OF_ASSETS_BY_CONSERVATORSHIP) getItem!: (listOfAssetsId: string) => ListOfAssets | undefined;

  get listOfAssets() {
    if (!this.conservatorship) return undefined;

    return this.getItem(this.conservatorship.id);
  }

  created() {
    return this.fetchList();
  }
}
